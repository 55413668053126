<!--
 * @Author: your name
 * @Date: 2021-03-02 13:58:36
 * @LastEditTime: 2022-08-12 14:54:21
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\login\login.vue
-->
<template>
  <div class="payResult">
    <div v-if="type==='success'">
      <img src="../../assets/img/paysuccess.png" alt="" class="logo">
      <!-- <div class="title"></div> -->
      <div class="tips">
        订单支付成功，已通知药房尽快发出。请耐心等待~
      </div>
    </div>
    <div v-else>
      <img src="../../assets/img/payerror.png" alt="" class="logo">
      <!-- <div class="title"></div> -->
      <div class="tips">
        抱歉, 您的订单支付失败
      </div>
    </div>
    <div class="btn btn-primary" @click="goback">
      返回首页
    </div>
  </div>
</template>

<script>
import { userModel } from '@/api/user'
export default {
  name: "PrePayResult",
  components: {
  },
  data() {
    return {
      type: 'success',
      payData: {},
      preOrderId: null,
      addressId: null,
      psfs: 1,
      // 
      href: ''
    }
  },
  computed: {
  },
  mounted() {
    this.preOrderId = this.$route.query.preOrderId
    this.psfs = this.$route.query.psfs
    this.addressId = this.$route.query.addressId
    this.type = this.$route.query.type
    this.href = window.location.href
    if (this.type === 'success') {
      this.onPaySuccess()
    }
    // this.getPayData()
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    onPaySuccess() {
      userModel.bindAddress(this.preOrderId, this.addressId).then(() => {})
    },
    countdown() {
      let time = 5
      this.timer = setInterval(() => {
        if (time === 0) {
          clearTimeout(this.timer)
          this.getroute()
        } else {
          this.text = `问诊签到(${time}秒后自动跳转)`
          time--
        }
      }, 1000)
    },
    getroute() {
      this.$router.push({
        path:'/consultation'
      })
    },
    goback() {
      this.$router.push({
        path:'/'
      })
    },
    goConsultation() {
      this.$router.push({
        path:'/consultation'
      })
    },
    getPayData() {
      // todo
    }
  }
}
</script>

<style scoped lang="less">
@import "../../assets/styles/variable.less";
.payResult {
  // padding: 32px;
  width: 100%;
  text-align: center;
  img {
    // margin-top: 52px;
    // margin-bottom: 100px;
    display: block;
    margin: 100px auto 52px auto;
    width: 271px;
    height: 264px;
  }
  .tips {
    width: 408px;
    height: 66px;
    margin: 0 auto;
    font-size: 24px;
    font-weight: 400;
    color: #999999;
    line-height: 33px;
  }
  // .btn {
  //   margin-top: 70px;
  //   width: 200px;
  // }
  .btn {
    width: 450px;
    height: 68px;
    line-height: 68px;
    font-size: 30px;
    text-align: center;
    border-radius: 12px;
    border-color: #1c9cf6;
    margin: 0 auto;
    margin-top: 30px;
  }
  .btn-default {
    color: #666;
    background: #ddd;
  }
  .btn-top {
    margin-top: 100px;
  }
  .btn-primary {
    color: #fff;
    background: #1c9cf6;
  }
}
</style>
